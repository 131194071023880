import React, { useState, useRef, useEffect } from 'react';
import { ImageBackground } from './HeroElements';
import Background from '../../assets/images/hero-background.jpg';
const BackgroundVideo = () => {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const playerRef = useRef();

  const playVideo = () => setVideoLoaded(true);

  useEffect(() => {
    const video = playerRef.current;
    if (playerRef && playerRef.current) {
      video.addEventListener('loadeddata', playVideo);
      return function cleanup() {
        video.removeEventListener('loadeddata', playVideo);
      };
    }
  }, []);

  const videoStyle = {
    minWidth: '100%',
    minHeight: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    overflowX: 'hidden',
    display: 'fixed',
    opacity: videoLoaded ? 1 : 0,
    transition: 'opacity, 1s ease-in-out',
    objectFit: 'cover',
  };
  // https://storage.coverr.co/videos/u3BZ8VheOREfxiKeqk9x97dijoaLLG2N?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6Ijg3NjdFMzIzRjlGQzEzN0E4QTAyIiwiaWF0IjoxNjIwNjUwMzgxfQ.vYyvxei8pTc819XloCaBoy8ZyylLLUKrEQtrFIPPtes
  return (
    <video ref={playerRef} playsInline autoPlay muted loop style={videoStyle}>
      <source
        src={
          require('../../assets/videos/hero-page/hero-video-webm.webm').default
        }
        type="video/webm"
      />
      <source
        src={
          require('../../assets/videos/hero-page/hero-video-mp4.mp4').default
        }
        type="video/mp4"
      />
      {!!document.createElement('video').canPlayType ? (
        'Your browser does not support the video tag.'
      ) : (
        <ImageBackground
          src={Background}
          backgroundLoaded={backgroundLoaded}
          alt="Background Image"
          onLoad={() => setBackgroundLoaded(true)}
        />
      )}
    </video>
  );
};

export default BackgroundVideo;

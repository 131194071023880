import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HeroContainer = styled.header`
  box-sizing: border-box;
  position: relative;
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
  z-index: 1;

  @media screen and (max-width: 1200px) {
    min-height: 800px;
  }

  @media screen and (max-width: 770px) {
    min-height: 105vh;
  }

  @media screen and (max-width: 425px) {
    min-height: 150vh;
  }
  @media screen and (max-width: 375px) {
    min-height: 105vh;
  }
`;

export const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden;
  background-color: rgb(12, 12, 12);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageBackground = styled.img`
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: lightskyblue;
  width: 100vw;
  min-height: 100%;
  filter: brightness(70%);
  visibility: ${({ backgroundLoaded }) =>
    backgroundLoaded ? 'visible' : 'hidden'};
  opacity: ${({ backgroundLoaded }) => (backgroundLoaded ? 1 : 0)};

  transition: opacity 0.3s linear;

  @media screen and (max-width: 1200px) {
    object-fit: cover;
    object-position: center;
  }
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  top: 40%;
  right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1200px) {
    right: 50%;
    margin-top: 10px;
    transform: translateX(50%);
  }

  @media screen and (max-width: 770px) {
    top: 30%;
    padding: 0;
    width: 100%;
    margin-top: 4rem;
  }
`;

export const HeroParagraph = styled.p`
  color: whitesmoke;
  font-size: 1.6em;
  text-align: center;
  max-width: 50ch;
  line-height: 1.7;
  font-weight: 600;

  @media screen and (max-width: 1200px) {
    padding-top: 0;
    font-size: 1.5em;
    margin-bottom: 2rem;
    width: 700px;
  }

  @media screen and (max-width: 768px) {
    font-size: 1.4em;
    width: 90%;
  }

  @media screen and (max-width: 375px) {
    font-size: 1em;
  }
`;

export const HeroContact = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 375px) {
    margin-top: 0;
  }
`;

export const ContactBtn = styled(Link)`
  border-radius: 5px;
  background: #0a51a0;
  border: 2px solid ${({ primary }) => (primary ? '#0a51a0' : '#010606')};
  border-bottom: 4px solid #05366b;
  white-space: nowrap;
  padding: 14px 48px;
  color: #d9e4dd;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-decoration: none;
  text-transform: uppercase;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;

  @media screen and (max-width: 1500px) {
    font-size: 18px;
    padding: 12px 36px;
  }

  @media screen and (max-width: 375px) {
    font-size: 16px;
  }

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #094c96;
    border-left: 2px solid #0a51a0;
    border-bottom: 2px solid #0a51a0;
    text-decoration: none;
    filter: brightness(85%);
    color: hsl(142deg 1% 52%) !important;
  }
  &:active,
  &:visited,
  &:focus {
    text-decoration: none;
    color: #d9e4dd;
  }
`;

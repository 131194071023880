import React, { Suspense, lazy } from 'react';
import HeroSpace from '../../components/HeroSpace';
import FallBackLoader from '../../components/FallBackLoader';

export const Home = () => {
  const TrendingSpace = lazy(() => import('../../components/TrendingSpace'));
  const InfoSpace = lazy(() => import('../../components/InfoSpace'));
  const MapBox = lazy(() => import('../../components/MapBox'));

  return (
    <>
      <HeroSpace />

      <Suspense fallback={<FallBackLoader rows={20} />}>
        <TrendingSpace />
      </Suspense>
      <Suspense fallback={<FallBackLoader rows={40} />}>
        <InfoSpace />
      </Suspense>
      <Suspense fallback={<FallBackLoader rows={20} />}>
        <MapBox />
      </Suspense>
    </>
  );
};

export default Home;

import React from 'react';
import Placeholder from 'rsuite/lib/Placeholder';

export default function BVPlaceholder({
  rows = 4,
  width = '100%',
  style,
  children,
  ...props
}) {
  return (
    <div style={{ width, ...style }} className="paragraph">
      {children}
      <Placeholder.Grid active graph="image" rows={rows} {...props} />
    </div>
  );
}

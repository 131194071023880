import React from 'react';
import {
  HeroContainer,
  HeroBackground,
  HeroContent,
  HeroParagraph,
  ContactBtn,
  HeroContact,
} from './HeroElements';
import { animateScroll as scroll } from 'react-scroll';
import BackgroundVideo from './BackgroundVideo';

const HeroSpace = () => {
  return (
    <HeroContainer id="home">
      <HeroBackground>
        <BackgroundVideo />
      </HeroBackground>
      <HeroContent>
        <HeroParagraph>
          Naša misija je logistička i stručna podrška veterinarskim
          organizacijama, proizvođačima hrane i klijentima u održavanju zdravlja
          životinja, prevenciji i kontroli bolesti i postizanju visokih
          standarda u sigurnosti hrane.
        </HeroParagraph>
        <HeroContact>
          <ContactBtn
            to="#"
            onClick={() => scroll.scrollToBottom()}
            primary="true"
          >
            Kontaktirajte Nas
          </ContactBtn>
        </HeroContact>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSpace;
